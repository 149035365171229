<template>
  <div class="spelling-detail">
    <div class="spelling-detail__header">
      <h2>{{ $t('More') }}</h2>
    </div>
    <div class="spelling-detail__items">
      <ul>
        <li>
          <h2>
            {{ $t('OrthographicLover') }}:
            <span class="gray">{{ detail.mistake }}</span>
          </h2>
        </li>
        <li>
          <h2>
            {{ $t('Description') }}:
            <span class="gray">{{ detail.description }}</span>
          </h2>
        </li>
        <li>
          <h2>
            {{ $t('DataTransfers') }}:
            <span class="gray"> {{ detail.created_at }}</span>
          </h2>
        </li>
        <li>
          <h2>
            {{ $t('linkError') }}:
            <a class="gray" :href="detail.page_url" target="_blank"> {{ $t('Go') }}</a>
          </h2>
        </li>
        <li>
          <h2>
            {{ $t('Status') }}:
            <a-switch v-model="detail.fixed" @change="onFixed" />
          </h2>
        </li>
      </ul>
    </div>

    <div>
      <a-button
        class="spelling_btns"
        style="margin-top: 10px"
        type="primary"
        @click="$router.go(-1)"
      >
        <i class="t-transition icons icon-chevron-left" />
        {{ $t('back') }}
      </a-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "SpellingDetail",
  data() {
    return {
      // columns,
      detail: [],
    };
  },
  async mounted() {
    let { data } = await this.$api.get(
      `/admin/syntax-mistake/${this.$route.params.id}`
    );
    console.log(data);
    this.detail = data.data;
  },

  methods: {
    async onFixed() {
      let form = {
        fixed: this.detail.fixed,
      };
      await this.$api.put(
        `/admin/syntax-mistake/${this.$route.params.id}`,
        form
      );
      // console.log(data);
      // this.detail = data.data;
    },
  },
};
</script>
<style>
.spelling-detail__header h2 {
  font-size: 30px;
  margin: 20px 0;
}
.spelling-detail__items ul li {
  padding: 10px 0;
}
.spelling-detail__items ul li span {
  padding-left: 10px;
}
.spelling_btns {
  display: flex;
  align-items: center;
}
.spelling_btns i {
  height: 15px;
  width: 15px;
}
.spelling-detail a:hover {
  color: #eb534d;
  text-decoration: underline;
}
.spelling-detail .gray {
  color: #9e9e9e;
}
</style>
